
import Vue from "vue";
import { mdiCloseBox, mdiMinusBox, mdiCheckboxBlankOutline } from "@mdi/js";
import { Rules } from "@/models/rules";

export default Vue.extend({
  name: "Selector",
  props: {
    items: {
      type: Array,
      required: true,
    },
    label: {
      type: String,
      required: false,
      default: "",
    },
    prependIcon: {
      type: String,
      required: false,
      default: "",
    },
    itemText: {
      type: String,
      required: false,
      default: "value",
    },
    itemValue: {
      type: String,
      required: false,
      default: "id",
    },
    multiple: {
      type: Boolean,
      required: false,
      default: false,
    },
    outlined: {
      type: Boolean,
      required: false,
      default: false,
    },
    concatText: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      seleccion: [] as string | string[] | null,
    };
  },
  computed: {
    rules(): Rules {
      return this.$store.state.validationRules;
    },
    icon(): string {
      if (this.multiple && this.seleccion) {
        if (this.seleccion.length <= 0) {
          return mdiCheckboxBlankOutline;
        }
        if (this.seleccion.length == this.items.length) {
          return mdiCloseBox;
        }

        return mdiMinusBox;
      }

      return "";
    },
  },
  watch: {
    multiple() {
      this.seleccion = [];
    },
  },
  methods: {
    toggle() {
      if (this.seleccion) {
        if (this.seleccion.length === this.items.length) {
          this.seleccion = [];
        } else {
          this.seleccion = this.items.map((i) => {
            const x = i as Record<string, unknown>;
            return x[this.itemValue] as string;
          });
        }
        this.change();
      }
    },
    change() {
      this.$emit("change", this.seleccion);
    },
  },
});
